import {Button, Form, InputGroup} from "react-bootstrap";
import React from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import {schema, Order} from "../models/Order";

type Step1Props = {
  order: Order,
  onSubmit: SubmitHandler<Order>
}

function Step1({order, onSubmit}: Step1Props): JSX.Element {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<Order>({
    resolver: yupResolver(schema),
    defaultValues: order
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>STEP 1 / 3</h2>
      <p>お申し込みいただくモデル様の情報を入力してください。</p>

      <div className="form-table">
        <div className={"form-row " + (errors.age ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="age">年齢</Form.Label>
          </div>
          <div className="form-row-control">
            <InputGroup>
              <input id="age" className={"form-control " + (errors.age ? "is-invalid" : "")} placeholder="年齢を入力" pattern="[0-9]*" type="text" maxLength={2} {...register("age", {required: {value: true, message: "必須項目です"}})} />
              <InputGroup.Text>歳</InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        {errors.age && (
          <div className="invalid-feedback">{errors.age?.message}</div>
        )}

        <div  className={"form-row " + (errors.height ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="height">身長</Form.Label>
          </div>
          <div className="form-row-control">
            <InputGroup>
              <input id="height" className="form-control" placeholder="身長を入力" pattern="[0-9]*" type="text" maxLength={3} {...register("height", {required: true})} />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        {errors.height && (
          <div className="invalid-feedback">{errors.height?.message}</div>
        )}

        <div className={"form-row " + (errors.weight ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="weight">体重</Form.Label>
          </div>
          <div className="form-row-control">
            <InputGroup>
              <input id="weight" className="form-control" placeholder="体重を入力" pattern="[0-9]*" type="text" maxLength={3} {...register("weight", {required: true})} />
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        {errors.weight && (
          <div className="invalid-feedback">{errors.weight?.message}</div>
        )}

        <div className={"form-row " + (errors.hair ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="hair">ワキ脱毛の有無</Form.Label>
          </div>
          <div className="form-row-control">
            <select id="hair" className="form-select" {...register("hair", {required: true})}>
              <option value="">選択してください</option>
              <option value="医療脱毛">医療脱毛</option>
              <option value="一般サロンでの脱毛">一般サロンでの脱毛</option>
              <option value="自己処理">自己処理</option>
              <option value="なにもしていない">なにもしていない</option>
            </select>
          </div>
        </div>
        {errors.hair && (
          <div className="invalid-feedback">{errors.hair?.message}</div>
        )}

        <div className={"form-row " + (errors.face ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="face">お顔を含めた撮影</Form.Label>
          </div>
          <div className="form-row-control">
            <select id="face" className="form-select" {...register("face", {required: true})}>
              <option value="">選択してください</option>
              <option value="できます">できます</option>
              <option value="できません">できません</option>
            </select>
          </div>
        </div>
        {errors.face && (
          <div className="invalid-feedback">{errors.face?.message}</div>
        )}

        <div  className={"form-row " + (errors.topless ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="topless">トップレスでの撮影</Form.Label>
          </div>
          <div className="form-row-control">
            <select id="topless" className="form-select" {...register("topless", {required: true})}>
              <option value="">選択してください</option>
              <option value="できます">できます</option>
              <option value="できません">できません</option>
            </select>
          </div>
        </div>
        {errors.topless && (
          <div className="invalid-feedback">{errors.topless?.message}</div>
        )}

        <div  className={"form-row " + (errors.publish ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="publish">動画の公開<br/><small>TwitterやYouTube等</small></Form.Label>
          </div>
          <div className="form-row-control">
            <select id="publish" className="form-select" {...register("publish", {required: true})}>
              <option value="">選択してください</option>
              <option value="動画公開OK(顔出しなし)">動画公開OK(顔出しなし)</option>
              <option value="静止画ならOK(顔出しなし)">静止画ならOK(顔出しなし)</option>
              <option value="一切公開は禁止">一切公開は禁止</option>
            </select>
          </div>
        </div>
        {errors.publish && (
          <div className="invalid-feedback">{errors.publish?.message}</div>
        )}

        <div className={"form-row " + (errors.sales ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="sales">動画の販売<br/></Form.Label>
          </div>
          <div className="form-row-control">
            <select id="sales" className="form-select" {...register("sales", {required: true})}>
              <option value="">選択してください</option>
              <option value="販売OK(顔出しあり)">販売OK(顔出しあり)</option>
              <option value="販売OK(顔出しなし)">販売OK(顔出しなし)</option>
              <option value="販売は禁止">販売は禁止</option>
            </select>
          </div>
        </div>
        {errors.sales && (
          <div className="invalid-feedback">{errors.sales?.message}</div>
        )}

        <div className={"form-row " + (errors.payment ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="payment">報酬受け取り方法</Form.Label>
          </div>
          <div className="form-row-control">
            <select id="payment" className="form-select" {...register("payment", {required: true})}>
              <option value="">選択してください</option>
              <option value="CASH">現金 (銀行振込)</option>
              <option value="AMAZON">Amazonギフト券</option>
            </select>
          </div>
        </div>
        {errors.payment && (
          <div className="invalid-feedback">{errors.payment?.message}</div>
        )}
      </div>

      <p>査定結果を受け取るメールアドレスを入力してください</p>
      <div className="form-table">
        <div  className={"form-row " + (errors.email ? "is-invalid" : "")}>
          <div className="form-row-label">
            <Form.Label htmlFor="email">メールアドレス</Form.Label>
          </div>
          <div className="form-row-control">
            <input id="email" className="form-control" placeholder="メールアドレスを入力" type="email" {...register("email", {required: true})} />
          </div>
        </div>
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>


      <div className="warning-bg">
        <h3>公開OKの場合、どこに掲載されますか?</h3>
        <p>
          Twitter等のSNS、YouTubeへ掲載される可能性があります。各プラットフォームのガイドラインに沿って編集します。<br />
          顔出しOKの場合もSNSへ投稿する場合は、モザイクやトリミング等の処理を行った上で<strong>顔出しなしで投稿します</strong>。
          <br />ポルノサイトへは投稿されません。
          <strong>公開OKの場合、査定額が上乗せされます。</strong>
        </p>

        <h3>販売OKの場合、どこで販売されますか?</h3>
        <p>現在準備中のワキ動画販売所(仮)にて販売いたします。
          購入には本人確認が必要なこと、販売する動画にはランダムな電子透かしを入れるシステムがありますので、違法な拡散を防ぐ対策をしております。
          <strong>販売OKの場合、査定額が大幅に上乗せされます。</strong>
        </p>
      </div>

      <div className="d-grid">
        <Button variant="success" size="lg" type="submit">次へ</Button>
      </div>
    </form>
  );
}

export default Step1;