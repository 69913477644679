import React, {useState} from "react"
import Layout from "../containers/Layout";
import {graphql, HeadFC, navigate} from "gatsby";
import {type SubmitHandler} from "react-hook-form";
import {collection, addDoc, serverTimestamp, Timestamp} from "firebase/firestore";
import Seo from "../containers/Seo";
import Step1 from "../components/Step1";
import type {Order} from "../models/Order";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import {firestore} from "../firebase";

function SubmitPage(): JSX.Element {
  const [order, setOrder] = useState<Order>({
    age: null,
    height: null,
    weight: null,
    hair: "",
    face: "",
    topless: "",
    publish: "",
    sales: "",
    payment: "",
    email: "",
    state: "review"
  });

  const [step, setStep] = useState<number>(1);

  const onOrderSubmit = (data: Order) => {
    setOrder(data);
    setStep(2);
    window.scrollTo({top: 0});
  }

  const onImageUpload = (path1:string, path2:string) => {
    order.path1 = path1;
    order.path2 = path2;
    setOrder(order);
    setStep(3);
    window.scrollTo({top: 0});
  }

  const onComplete = (order:Order) => {
    order.createdAt = serverTimestamp() as Timestamp;
    addDoc(collection(firestore, "orders"), order).then(snapshot => {
      navigate("/complete");
    });
  }

  return (
    <Layout>
      <main>
        <div className="hero-unit">
          <h1>査定申し込み</h1>

          <div className="zag"/>
        </div>
        <div className="container">
          {/*<Step1 order={order} onSubmit={onStep1Submit} />*/}
          <SubmitContent
            step={step}
            order={order}
            onSubmit={onOrderSubmit}
            onUpload={onImageUpload}
            onBack={() => {setStep(step - 1)}}
            onComplete={onComplete}
          />
        </div>
      </main>
    </Layout>
  );
}

type SubmitContentProps = {
  step: number,
  order: Order,
  onSubmit: SubmitHandler<Order>,
  onUpload: (path1: string, path2: string) => void,
  onBack: Function,
  onComplete: (order:Order) => void
};

function SubmitContent({step, order, onSubmit, onUpload, onBack, onComplete}: SubmitContentProps): JSX.Element {
  switch (step) {
    case 2:
      return <Step2 onUpload={onUpload} onBack={onBack} />;
    case 3:
      return <Step3 order={order} onBack={onBack} onComplete={onComplete} />
    default:
      return <Step1 order={order} onSubmit={onSubmit} />
  }
}

export const query = graphql`
  query SubmitQuery {
    site {
      siteMetadata {
        title
        description
        twitter
        siteUrl
      }
    }
  }
`

export const Head: HeadFC = ({data}: {data: any}) => (
  <Seo data={data}>
    <title id="title">査定申し込み | {data.site.siteMetadata.title}</title>
    <meta id="description" name="description" content={"自撮りワキ動画の買取査定はこちらからお願いいたします。かんたんな入力フォームから応募いただくと、24時間以内に査定額をお知らせします。査定内容に同意いただけない場合は、応募いただいた情報を速やかに削除いたします。"} />
  </Seo>
);

export default SubmitPage;
