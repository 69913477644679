import ImageUploading, {ImageListType} from "react-images-uploading";
import {StaticImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import { ref, uploadBytes, type UploadResult } from "firebase/storage";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import {storage} from "../firebase";

type Step2Props = {
  onBack: Function,
  onUpload: (path1:string, path2:string) => void
}

function Step2({onBack, onUpload}: Step2Props) {
  const [image1, setImage1] = useState<ImageListType>([]);
  const [image2, setImage2] = useState<ImageListType>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [path1, setPath1] = useState<string|null>(null);
  const [path2, setPath2] = useState<string|null>(null);

  const processImage = (file: File) => {
    return new Promise<UploadResult>((resolve, reject) => {
      const extension = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${extension}`;
      const storageRef = ref(storage, `temporary/${fileName}`);
      uploadBytes(storageRef, file as Blob).then(snapshot => {
        resolve(snapshot);
        //console.log(snapshot);
      });
    })
  }

  const onImage1Changed = (imageList: ImageListType) => {
    setImage1(imageList);
    processImage(imageList[0].file!).then(result => {
      setPath1(result.metadata.fullPath);
      setValidated((path2 != null));
    });
  }

  const onImage2Changed = (imageList: ImageListType) => {
    setImage2(imageList);
    processImage(imageList[0].file!).then(result => {
      setPath2(result.metadata.fullPath);
      setValidated((path1 != null));
    });
  }

  return (
    <div>
      <h2>STEP 2 / 3</h2>
      <p>参考写真を選択してください</p>

      <div className="warning-bg">
        <div>
          <h3>ご注意</h3>
        </div>

        <p>ご本人以外の写真を使用することは利用規約で禁じております。また、他人の写真を使用することによる刑事上・民事上の責任はお客様が負うものとします。</p>

        <div><h3>写真の取り扱いについて</h3></div>
        <p>
          送信いただいた写真は査定額の算出の目的のみに使用し、査定結果にかかわらず査定後に削除いたします。<br />
          動画での顔出しNGを選択された場合でも査定時のみ顔写真をご提出ください。<br />
          写真に加工がされている場合査定ができない場合があります。無加工の写真をご提出ください。
        </p>
      </div>

      <p>
        <strong>腋のアップ写真</strong><br />
        参考写真のようにワキをアップで撮影してください。
      </p>

      <div className={"image-upload"}>
        <div className={"uploader"}>
          <ImageUploading
            value={image1}
            onChange={onImage1Changed}
            acceptType={["jpg", "jpeg", "png", "heif"]}
          >
            {({imageList, onImageUpload}) => {
              return (
                <div className="uploadable">
                  {imageList.length > 0 && (
                    <img src={imageList[0].dataURL} alt={""} />
                  )}
                  <button type="button" onClick={onImageUpload}>
                    タップして写真を選択してください
                  </button>
                </div>
              );
            }}
          </ImageUploading>
        </div>
        <div className={"sample"}>
          <StaticImage src={"../images/sample1.png"} alt={"参考写真"} width={100} />
          <div>参考写真</div>
        </div>
      </div>

      <p>
        <strong>お顔が写った写真</strong><br />
        参考写真のようにお顔を含めて撮影してください。
      </p>

      <div className={"image-upload"}>
        <div className={"uploader"}>
          <ImageUploading
            value={image2}
            onChange={onImage2Changed}
          >
            {({imageList, onImageUpload}) => {
              return (
                <div className="uploadable">
                  {imageList.length > 0 && (
                    <img src={imageList[0].dataURL} alt={""} />
                  )}
                  <button type="button" onClick={onImageUpload}>
                    タップして写真を選択してください
                  </button>
                </div>
              );
            }}
          </ImageUploading>
        </div>
        <div className={"sample"}>
          <StaticImage src={"../images/sample2.png"} alt={"参考写真"} width={100} />
          <div>参考写真</div>
        </div>
      </div>

      <div className="d-grid gap-3">
        <Button variant="success" size="lg" disabled={!validated} onClick={() => { onUpload(path1!, path2!) }}>次へ</Button>
        <Button variant="secondary" size="lg" onClick={() => { onBack() }}>戻る</Button>
      </div>
    </div>
  );
}

export default Step2;