import * as yup from "yup";
import {jaJP} from "./locale";
import type {Timestamp} from "firebase/firestore";

yup.setLocale(jaJP);

export declare type Order = {
  age: number|null
  height: number|null,
  weight: number|null,
  hair: string|null,
  face: string|null,
  topless: string|null,
  publish: string|null,
  sales: string|null,
  payment: string|null,
  email: string|null,
  path1?: string,
  path2?: string,
  message?: string,
  createdAt?: Timestamp,
  state?: string
}

export const schema = yup.object({
  age: yup.number().typeError("数字で入力してください").required("必須項目です").positive().integer().max(40),
  height: yup.number().typeError("数字で入力してください").positive().integer().max(200).required(),
  weight: yup.number().typeError("数字で入力してください").positive().integer().max(100).required(),
  hair: yup.string().required(),
  face: yup.string().required(),
  topless: yup.string().required(),
  publish: yup.string().required(),
  sales: yup.string().required(),
  payment: yup.string().required(),
  email: yup.string().email().required()
}).required();