import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {Order} from "../models/Order";


type Step3Props = {
  order: Order,
  onBack: Function,
  onComplete: (order:Order) => void
}

function Step3({order, onBack, onComplete}: Step3Props) {
  const [validated, setValidated] = useState<boolean>(false);
  const [term1, setTerm1] = useState<boolean>(false);
  const [term2, setTerm2] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setValidated(term1 && term2);
  }, [term1, term2])

  const onSubmit = () => {
    order.message = message;
    onComplete(order);
  };

  return (
    <div>
      <h2>STEP 3 / 3</h2>
      <p>入力内容を確認してください</p>

      <div className="form-table">
        <div className="form-row">
          <div className="form-row-label">年齢</div>
          <div className="form-row-control">{order.age}歳</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">身長</div>
          <div className="form-row-control">{order.height}cm</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">体重</div>
          <div className="form-row-control">{order.weight}kg</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">ワキ脱毛の有無</div>
          <div className="form-row-control">{order.hair}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">お顔を含めた撮影</div>
          <div className="form-row-control">{order.face}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">トップレスでの撮影</div>
          <div className="form-row-control">{order.topless}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">動画の公開</div>
          <div className="form-row-control">{order.publish}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">動画の販売</div>
          <div className="form-row-control">{order.sales}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">報酬受け取り方法</div>
          <div className="form-row-control">{order.payment}</div>
        </div>
        <div className="form-row">
          <div className="form-row-label">メールアドレス</div>
          <div className="form-row-control">{order.email}</div>
        </div>
      </div>

      <p>メッセージ (自由記入)</p>
      <textarea name="message" id="message" rows={5} className="form-control mb-5"
                placeholder="連絡事項や査定の参考になる情報があれば入力してください"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
      />

      <p>以下の利用規約に従います</p>
      <div className="form-table">
        <div className="form-row">
          <Form.Check
            type="switch"
            id="term1"
            label="本人による申し込みです"
            checked={term1}
            onChange={(e) => {
              setTerm1(e.target.checked);
            }}
          />
        </div>
        <div className="form-row">
          <Form.Check
            type="switch"
            id="term1"
            label="他者の権利を侵害していません"
            checked={term2}
            onChange={(e) => {
              setTerm2(e.target.checked);
            }}
          />
        </div>
      </div>


      <div className="d-grid gap-3">
        <Button variant="success" size="lg" disabled={!validated} onClick={onSubmit}>査定依頼する</Button>
        <Button variant="secondary" size="lg" onClick={() => { onBack() }}>戻る</Button>
      </div>
    </div>
  );
}

export default Step3;